<template>
  <div style="padding:0 20px;">
    <h1>苃缘App用户须知</h1>
    <p>用户在申请注册流程中点击同意本须知之前，应当认真阅读本须知。</p>
    <p>用户直接或通过各类方式间接使用苃缘App的服务和数据的行为，即表示用户已充分阅读、理解并接受本须知的全部内容，并与苃缘App达成一致，成为苃缘App的用户。阅读本须知的过程中，如果用户不同意本须知或其中任何条款约定，用户应立即停止注册程序。</p>
    <div class="title">一、总则</div>
    <p>1、苃缘App是由武汉水凝网络科技有限公司（以下简称“武汉水凝”）开发设计的App软件，武汉水凝对该App软件及其信息享有所有权及知识产权。</p>
    <p>2、用户应当同意本须知的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击"同意"按钮即表示用户与武汉水凝达成协议，完全接受本须知项下的全部条款。</p>
    <p>3、用户注册成功后，武汉水凝将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任，必须合法使用，不得违反法律法规和规章制度的规定。</p>
    <p>4、用户一经获得帐号，有权利用该账号使用苃缘App软件的单项服务，当用户使用苃缘App各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及苃缘App在该单项服务中发出的各类公告的同意。</p>
    <p>5、苃缘App及武汉水凝有权随时对本须知条款进行修改，有权随时变更、中断或终止部分或全部网络服务，并不需对用户或任何第三方负责和为此承担任何责任。修改后的服务条款一旦公布即有效代替原来的服务条款。用户可随时登录苃缘App查阅最新版服务条款。苃缘App随时发布的与该服务相关的规则或说明，这些规则或说明均为构成本服务条款的一部分。用户如果不同意服务条款的修改，可以主动取消已经获得的网络服务；如果用户继续使用网络服务，则视为用户已经接受服务条款的修改。</p>
    <p>6、若用户是18周岁以下的未成年人，在使用苃缘App的服务前，应确保事先取得监护人的同意，并与监护人共同审阅本用户协议。如用户以任何行为实际使用苃缘App的服务，即表示用户及其监护人接受了本用户协议，并同意受本用户协议各项条款的约束。</p>
    <p>7、苃缘App提供的某些服务为收费服务。对于收费服务，苃缘App会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费服务。如用户拒绝支付相关费用，则苃缘App有权不向用户提供该等收费服务。苃缘App和合作公司有权按需要修改或变更所提供的收费服务、收费标准、收费方式、服务费等。</p>
    <p>8、在注册成为苃缘App用户时应正确填写个人信息，保证资料真实、完整、有效，尤其是个人重要信息如邮箱地址、手机号码、联系地址等，以便本系统在必要时通过用户注册信息及时与用户本人取得联系。如果资料发生变化，用户应及时更改，否则对于可能造成的损失将由用户自己承担。在安全完成本服务的登记程序并收到一个密码及帐号后，用户应保护密码及帐号的安全。用户应对任何人利用用户的密码及帐号所进行的活动负完全的责任，苃缘App无法对非法或未经您授权使用您帐号及密码的行为作出甄别，因此苃缘App不承担任何责任。当用户每次上网或使用其他服务完毕后，会将有关帐号安全退出。</p>
    <div class="title">二、服务流程</div>
    <p style="text-indent: unset;">1、注册与登录</p>
    <p>当您注册、登录苃缘App时，您可以通过手机号创建账号，并且您可以完善相关的网络身份识别信息（头像、昵称），您还可以根据自身需求选择性填写性别、生日、地区及其他个人介绍来完善您的信息</p>
    <p>您也可以使用第三方账号登录并使用，您将授权我们获取您的第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与苃缘App账号绑定，使您可以直接登录并使用本产品的相关服务。</p>
    <p style="text-indent: unset;">2、认证</p>
    <p>在您使用身份认证的功能或服务时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码）以完成实名认证，这些信息属于个人敏感信息，您可以拒绝提供，但您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</p>
    <p style="text-indent: unset;">3、语音房间</p>
    <p>用户可自发根据某个话题创建语音聊天房间，在房间中您可与1人或多人进行实时语音会话，房间需严格遵守《苃缘绿色公约》《苃缘房间名与房间内容规范》，房主与房间管理需维护好房间秩序，并做好良好的监督工作，严禁一切涉及黄赌毒政内容，官方超级管理员有权对房间内容进行巡查管理，凡涉及黄赌毒政内容者官方有权制止，情节严重者必要时可采取相关法律手段</p>
    <p style="text-indent: unset;">4、互动交流</p>
    <p>您可与苃缘App其他用户，房间，话题进行关注，并之进行互动，互动内容包含但不限于查看资料，跟随，点赞，分享，聊天</p>
    <div class="title">三、苃缘App使用规则</div>
    <p>1、用户直接或通过各类方式间接使用苃缘App的服务和数据的行为，即表示用户同意苃缘App及武汉水凝获取用户信息及数据。</p>
    <p>2、如因GPRS等信号不良网络缺失等导致苃缘App无法正常使用的，苃缘App及武汉水凝不承担任何责任。</p>
    <p>3、苃缘App注重保护用户的个人隐私，包括用户名、电子邮箱、个人健康数据等资料，未获得用户的授权许可，苃缘App不会向其他第三方泄露，用户自行泄露的除外（比如添加自己的真实信息到个人首页，其他第三方均可通过首页的浏览获取该信息），如因黑客等苃缘App无法控制原因导致用户个人隐私泄露的，苃缘App不承担任何责任。但用户在使用搜索引擎输入的关键字、最近浏览等不属于个人隐私内容。</p>
    <p>4、包括移动运营商和苃缘App上决定使用的非武汉水凝的其他应用程序在内的第三方会同时提供相关产品和服务，使用第三方的产品和服务视为用户认可并同意第三方的服务条款，请用户自行审阅第三方的相关政策规定，对其他第三方提供的产品和服务，苃缘App不承担任何责任。</p>
    <p>5、苃缘App不对用户做出任何承诺（包括但不限于）：不保证服务不会中断；不保证诊断结果或数据分析的安全性、正确性、及时性及合法性；不保证苃缘App任何产品和服务都能得到解决、苃缘App中的外部链接及其指向的内容的准确性、稳定性及完整性等。因网络状况、通讯线路、第三方网站或服务商等任何原因导致用户不能正常使用苃缘App，苃缘App不承担任何法律责任。</p>
    <p>6、为了更好地提高用户的体验度，提高服务，苃缘App会基于技术必要性收集一些有关移动设备特定信息（包括但不限于：用户设备和硬件ID、设备类型、用户请求类型及内容、IP地址、数据缓存等），苃缘App会根据用户的个性化信息来处理用户请求，如选项中的某项设置。同时也会使用这些信息来进行必要的数据分析，并以此为基础，开发新功能或改进产品或服务的整体质量。用户直接或通过各类方式间接使用苃缘App的服务和数据的行为，即视为用户同意苃缘App及武汉水凝获取上述特定信息并采取后续手段。</p>
    <p>7、为了用户的人身及财产安全，请用户尽量不要向其他第三方发送或共享定位，因用户的发送或共享行为产生责任由用户个人自行承担。</p>
    <p>8、苃缘App及武汉水凝设置了专门投诉、举报、拉黑渠道，如任何单位或个人发现苃缘App上信息可能侵犯其权利的，应及时以书面形式提交符合苃缘App要求的合法权利通知，并提供身份证明、权属证明及侵权情况说明。苃缘App收到上述信息后会根据用户提供的证据尽快配合处理。</p>
    <p>9、用户明确同意其使用苃缘App的产品及服务，所存在的风险将完全由用户本人承担；因用户使用苃缘App而产生的一切责任也由其本人承担。苃缘App及武汉水凝对用户及任何第三方不承担任何责任。</p>
    <p>10、用户应当通过真实身份信息认证注册账号，且用户提交的账号名称、头像和简介等注册信息中不得出现违法和不良信息，经苃缘App审核，如存在上述情况，苃缘App将不予注册通过；同时，在注册后，如发现用户以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息的，苃缘App有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，由用户自行承担，苃缘App及武汉水凝不负任何责任。</p>
    <p>11、用户应当妥善苃缘App的帐号信息，避免帐号及个人信息的泄露，否则由此产生的责任由用户自行承担。如用户转售或出借移动设备的，应注意退出苃缘App个人账号登入，否则由此产生责任由用户自行承担。</p>
    <p>12、对于因苃缘App及武汉水凝合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、物资短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等不可抗力，致使苃缘App及武汉水凝延迟或未能履约的，苃缘App及武汉水凝不对用户承担任何责任。</p>
    <p>13、苃缘App对于任何通过本软件而获得的他人的信息、内容或者广告宣传等任何资讯（以下统称“信息”），不保证真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，须自行甄别真伪和谨慎预防风险。否则，无论何种原因，苃缘App及武汉水凝不对任何非与本网站直接发生的交易和/或行为承担任何直接、间接、附带或衍生的损失和责任。</p>
    <p>14、<span style="font-weight:bold;">用户不应将其帐号、密码转让、出售或出借予他人使用，若用户授权他人使用帐户，应对被授权人在该帐户下发生所有行为负全部责任。</span>如用户发现其帐号遭他人非法使用或存在其它安全问题等情况，应立即通知苃缘App管理员。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，苃缘App及武汉水凝不承担任何责任。如用户违反相关法律法规和本协议各项规定，苃缘App及武汉水凝有权不经通知删除该帐号，并停止为该用户提供相关网络服务。</p>
    <p>15、用户在使用苃缘App服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用苃缘App进行任何违法或不正当的活动，否则上述责任由用户自行承担。</p>
    <p>16、在如下情况下，苃缘App可能会披露用户的信息：</p>
    <p>（1）事先获得用户的授权；</p>
    <p>（2）用户使用共享功能；</p>
    <p>（3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；</p>
    <p>（4）以学术研究或公共利益为目的；</p>
    <p>（5）为维护若道平台的合法权益，例如查找、预防、处理欺诈或安全方面的问题；</p>
    <p>（6）用户利用该账户引导舆论，对App或他人造成重大不利影响，需要及时遏制不利影响继续扩散的；</p>
    <p>（7）符合相关服务条款或使用协议的规定。</p>
    <p>17、用户知悉并授权，苃缘App及武汉水凝在必需的情况下使用或与关联公司同步用户的信息，用以为用户提供相关服务。</p>
    <p>18、苃缘App基于用户账户管理的需要，如用户连续____个月未登入账户，或苃缘App认为用户违反本须知等情况下，苃缘App有权关闭用户账户并删除账户上存储的相关信息或者采取禁言等其他合理措施，由此产生后果由用户自行承担。</p>
    <p>19、用户在使用苃缘App时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用苃缘App进行任何违法或不正当的活动，包括但不限于下列行为∶</p>
    <p>（1）反对宪法确定的基本原则的；</p>
    <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>（3）损害国家荣誉和利益的；</p>
    <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>（5）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
    <p>（6）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>（7）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>（8）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的、辱骂性的、恐吓性的、成人情色的或其他任何非法的信息资料；</p>
    <p>（9）侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>（10）发布含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
    <p>（11）以非法民间组织名义活动的；</p>
    <p>（12）发布包含种族、肤色、性别、性取向、宗教、民族、地域、残疾、社会经济状况等歧视内容的言论和消息；</p>
    <p>（13）未经公开报道、未经证实的消息（发布本人亲身经历的事件请注明并将联络办法告知苃缘App管理员）;</p>
    <p>（14）可能侵犯第三方权益的文件或者信息，例如（包括但不限于）：病毒代码、黑客程序、软件破解注册信息、他人隐私、人格权以及其他信息；</p>
    <p>（15）抄袭及剽窃他人的作品，转载及引用请注明出处，不得上贴原作者禁止转载的文章；</p>
    <p>（16）缺乏理性讨论诚意、无理纠缠、恶意灌水等浪费版面资源的信息；</p>
    <p>（17）明显缺乏章法、错别字过多、内容晦涩空泛的帖子；</p>
    <p>（18）包含影响用户正常浏览的内容或格式的文章；</p>
    <p>（19）重复帖、测试帖、过于私人化的聊天水帖；</p>
    <p>（20）涉及他人隐私、有意模仿他人注册名或冒充苃缘App管理人员发布的信息；</p>
    <p>（21）破坏苃缘App利益的其他内容；</p>
    <p>（22）未经苃缘App书面同意，请勿发布任何形式的广告；</p>
    <p>（23）请勿在帖子、作品中（标题和内容）加入各种奇形怪状的符号；</p>
    <p>（24）禁止恶意翻帖、带节奏的行为；</p>
    <p>（25）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</p>
    <p>20、处罚规定：对于违反法律法规的作品、素材等等，管理员有权直接删改；对于违反苃缘App管理规定的用户，管理员有权删除内容并对该用户采取警告、禁止使用直至取消用户资格的处理；对于严重违反苃缘App管理规定的，管理员有权取消用户账号。取消用户账号，一般应遵循"先通知用户，提出警告，在劝告无效下予以执行"这项原则，但情节特别严重者除外。</p>
    <p>21、苃缘App及武汉水凝在提供支持和服务的过程中，将不可避免的产生渠道技术服务成本，故有权从用户的使用App的过程中扣除相应成本。伴随服务成本可能发生的变动，苃缘App及AAA有权调整成本比例、决定暂时或永久免收等。</p>
    <div class="title">四、用户的权利义务（包括但不限于）</div>
    <p>1、用户享有网络平台用户的各种权利，可享受平台提供的各项服务。</p>
    <p>2、用户在苃缘App所进行的所有活动应遵守本网站及相关第三方合作平台的管理条例，遵守中华人民共和国法律、法规，并自行承担因从事苃缘App平台活动直接或间接引起的一切法律责任，用户如不在国内居住，还应特别注意遵守所在国家和地区所有有关的法律和法规。</p>
    <p>3、用户要注意保管好用户名及密码，用户如发现帐号遭他人非法使用，应立即通知苃缘App相关人员及客服。因黑客行为或用户保管疏忽导致帐号、密码遭他人非法使用，苃缘App不承担任何责任。</p>
    <p>4、用户有权对若道平台提出批评、意见、建议，有权就苃缘App相关管理工作向网站提出咨询。</p>
    <p>5、用户应遵守本须知的约定，向苃缘App平台提供真实、合法、准确、有效的注册资料，用户保证不以他人资料在服务平台进行注册，并保证其诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性及安全性，保证苃缘App平台及其他用户可以通过上述联系方式与自己进行联系。</p>
    <p>6、用户的相关资料实际变更时应及时更新有关信息，并将该信息及时告知苃缘App平台及其他用户。</p>
    <p>7、用户在苃缘App平台上的言行需遵守国家法律、法规等规范性文件及苃缘App的各项规则和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。如果因此产生任何法律后果的，用户应以自己的名义独立承担法律责任。</p>
    <p>8、用户应就其在谈判、签署和履行本须知过程中所知悉或获取的苃缘App的有关信息和资料予以保密。未经苃缘App平台书面同意，用户不得向第三方披露该等信息。该保密义务不因本须知的任何可能的无效、解除或终止而无效、解除或终止。如本须知宣布无效、解除或终止，双方应继续持续履行相关的保密义务。</p>
    <p>9、用户有义务保护其他用户的个人信息及隐私信息的安全。用户因泄露信息造成的其他用户损害的，由该用户向其他用户及第三人赔偿损失。</p>
    <p>10、用户承诺其接受苃缘App平台选择的第三方支付机构提供的支付方式作为其通过服务平台达成的交易的收费工具。</p>
    <p>11、用户需遵守网络道德，注意网络礼仪，做到文明上网。</p>
    <div class="title">五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</div>
    <p>1、对于用户通过苃缘App平台服务上传到苃缘App平台网站上可公开获取区域的任何内容，用户同意苃缘App平台在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</p>
    <p>2、苃缘App平台拥有本网站内所有资料的版权。</p>
    <p>3、未经苃缘App平台许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用苃缘App平台商标。否则，苃缘App平台将依法追究法律责任。</p>
    <p>4、苃缘App平台所有内容仅代表作者自己的立场和观点，与苃缘App平台无关，由作者本人承担一切法律责任；</p>
    <p>5、苃缘App平台的文字、图片、音频、视频等版权均归苃缘App平台享有或苃缘App平台与作者共同享有，未经苃缘App平台许可，不得任意转载。转贴文章，请注明原始出处和时间，并注意原作者的版权声明，用户需承担转贴可能引起的版权责任；</p>
    <p>6、苃缘App平台特有的标识、版面设计、编排方式等版权均属苃缘App平台享有，未经苃缘App平台许可，不得任意复制或转载。使用苃缘App平台的任何内容均应注明“来源于苃缘App平台”及署上作者姓名，按法律规定需要支付稿酬的，应当通知苃缘App平台及作者并支付稿酬，并独立承担一切法律责任；</p>
    <p>7、苃缘App平台有权将在苃缘App平台发表的文章或图片（附带版权声明除外）自行使用或者与他人合作，包括但不限于网站、电子杂志、平面出版等。</p>
    <p>8、根据版面管理需要有权删除转帖；</p>
    <p>9、任何转载、引用发表于苃缘App平台的版权文章须符合以下规范：</p>
    <p>（1）征得文章或图片原作者同意，注明作者及文章及图片的出处为苃缘App平台"或苃缘App平台的某具体服务，并向作者支付稿酬。</p>
    <p>（2）任何文章或图片的修改或删除均应保持作者原意并征求原作者同意，并注明授权范围。</p>
    <p>10、恶意转载苃缘App平台内容的，苃缘App平台保留将其诉诸法律的权利。</p>
    <p>11、因帖文引起的法律侵权，单列或者追加苃缘App平台为被告的，请当事人向苃缘App平台所在地法院提起诉讼。</p>
    <div class="title">六、青少年用户特别提示</div>
    <p>1、青少年用户必须遵守全国青少年网络文明公约：</p>
    <p>2、要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。</p>
    <p>3、不得随意打赏主播，慎重对待需要支付费用的项目以及链接。</p>
    <div class="title">七、其他</div>
    <p>1、本须知之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用商业惯例和（或）行业惯例。如用户就本须知内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，由苃缘App平台所在地人民法院裁决。</p>
    <p>2、如本须知中的任何条款无论因何种原因完全或部分无效或不具有执行力，本须知的其余条款仍应有效并且具有约束力。</p>
    <p>3、苃缘App一直致力于持续、快速地更新数据，丰富用户的生活，让用户有一个可以沟通交流的平台，但由于技术或客观原因等，苃缘App数据会存在一定滞后性，请用户以实际情况为准。</p>
    <p>4、苃缘App对本须知拥有最终解释权。</p>
  </div>
</template>

<script>
// import {appActivityCreate} from "@/api/ranking";

export default {
  name: 'agreementIOS',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
}
</style>
